export const NATIONAL_ID_TYPE_ERROR_MSG = "* ID Type is require";
export const NATIONAL_ID_ERROR_MSG = "* ID Number is required";
export const DUPLICATE_NATIONAL_ID_ERR_MSG = "* Beneficiary with this ID already exists";
export const NAME_ERROR_MSG = "* Beneficiary Name is required";
export const INVALID_NAME_ERR_MSG = "* Beneficiary Name should have only alphabets"
export const DUPLICATE_NAME_YOB = "* Beneficiary with same Name and Age already exists"
export const MINIMUM_LENGTH_OF_NAME_ERROR_MSG = "* Name should have at least 1 characters"
export const MAXIMUM_LENGTH_OF_NAME_ERROR_MSG = "* Name should have maximum 99 characters"
export const STATE_ERROR_MSG = "* State is required";
export const DISTRICT_ERROR_MSG = "* District is required";
export const NATIONALITY_ERROR_MSG = "* Nationality is required";
export const GENDER_ERROR_MSG = "* Gender is required";
export const AADHAAR_ERROR_MESSAGE = "* Invalid Aadhaar number";
export const EMAIL_ERROR_MESSAGE = "* Email ID is not matching";
export const PINCODE_ERROR_MESSAGE = "* Invalid pin code";
export const INVALID_BENEFICIARY_ERROR_MSG = "Sorry, the beneficiary is currently not eligible to register for the PROGRAM_NAME program"
